import React from "react";

const Info = () => {
  return (
    <div className="info-container">
      <div className="info-left">
        <div className=" info-left-details  d-flex flex-row gap-2 justify-content-center">
          <div className=" d-flex flex-column justify-content-center align-self-center">
            <h6>Trending This Week</h6>
            <p  className="prace-1">
              {" "}
              Lorem ipsum dolor sit amet, consecte tuer adipiscing elit, sed
              diam nonummy nibh euismod tincidunt ut laoreet dolore. Phasellus
              viverra nulla ut metus varius laoreet. To make the patagraph
              largerQuisque rutrum aenean this is necessary imperdiet. nulla ut
              metus varius laoreet. To make the patagraph largerQuisque rutrum
              aenean this is necessary imperdiet.
            </p>
            <button
              className=" btn btn-large"
              style={{ background: "#d2b783" }}
            >
              {" "}
              Read More
            </button>
          </div>
        </div>
      </div>
      <div className="info-right">
        <div>
          {" "}
          <div className=" d-flex flex-column justify-content-center align-self-center">
            <h6>Trending This Week</h6>
            <p className="prace-1">
              {" "}
              Lorem ipsum dolor sit amet, consecte tuer adipiscing elit, sed
              diam nonummy nibh euismod tincidunt ut laoreet dolore. Phasellus
              viverra nulla ut metus varius laoreet. To make the patagraph
              largerQuisque rutrum aenean this is necessary imperdiet. nulla ut
              metus varius laoreet. To make the patagraph largerQuisque rutrum
              aenean this is necessary imperdiet.
            </p>
          </div>
        </div>
        <div>
          {" "}
          <div className=" d-flex flex-column justify-content-center align-self-center">
            <h6>Trending This Week</h6>
            <p className=" prace-1" style={{ fontSize: "1rem" }}>
              {" "}
              Lorem ipsum dolor sit amet, consecte tuer adipiscing elit, sed
              diam nonummy nibh euismod tincidunt ut laoreet dolore. Phasellus
              viverra nulla ut metus varius laoreet. To make the patagraph
              largerQuisque rutrum aenean this is necessary imperdiet. nulla ut
              metus varius laoreet. To make the patagraph largerQuisque rutrum
              aenean this is necessary imperdiet.
            </p>
          </div>
        </div>
        <div>
          {" "}
          <div className=" d-flex flex-column justify-content-center align-self-center">
            <h6>Trending This Week</h6>
            <p className=" prace-1">
              {" "}
              Lorem ipsum dolor sit amet, consecte tuer adipiscing elit, sed
              diam nonummy nibh euismod tincidunt ut laoreet dolore. Phasellus
              viverra nulla ut metus varius laoreet. To make the patagraph
              largerQuisque rutrum aenean this is necessary imperdiet. nulla ut
              metus varius laoreet. To make the patagraph largerQuisque rutrum
              aenean this is necessary imperdiet.
            </p>
          </div>
        </div>
        <div>
          {" "}
          <div className=" d-flex flex-column justify-content-center align-self-center">
            <h6>Trending This Week</h6>
            <p className=" prace-1">
              {" "}
              Lorem ipsum dolor sit amet, consecte tuer adipiscing elit, sed
              diam nonummy nibh euismod tincidunt ut laoreet dolore. Phasellus
              viverra nulla ut metus varius laoreet. To make the patagraph
              largerQuisque rutrum aenean this is necessary imperdiet. nulla ut
              metus varius laoreet. To make the patagraph largerQuisque rutrum
              aenean this is necessary imperdiet.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;

import React from 'react'
import { Link } from 'react-router-dom';

const FaqsComponents = ({t,data}) => {
  return (
    <div className="container">
    <div className="row">
      {/* {isLoading && <div className="preloaderCount">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>} */}

      <div className="col-lg-12 mb-3">
        <div className="faqInfo">
          <div className="pageTitle text-center">
            <h1>{t("Frequently Asked Question")}</h1>
            <p>{t("faqs")}</p>
          </div>
        </div>
      </div>

      <div className="tab-content" id="nav-tabContent">
        <div
          className="tab-pane fade show active"
          id="nav-seeds"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        >
          <div className="faqTabText">
            <h4 className="text-decoration-underline text-center mt-4 mb-4">
              {t("Luxliving Interior")}
            </h4>
            <div className="row">
              <div className="col-lg-6 col-m-6">
                <div className="faqItem">
                  <div className="accordion" id="accordionExample">
                    {data &&
                      data.map((item, i) => {
                        return (
                          <div
                            key={item._id}
                            className="accordion-item faqAccordionInfo"
                          >
                            <h2
                              className="accordion-header"
                              id={`heading${i}`}
                            >
                              <button
                                className="accordion-button faqAccordionBtn"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseOne${i}`}
                                aria-expanded="true"
                                aria-controls={`collapseOne${i}`}
                              >
                                {item.question}
                              </button>
                            </h2>
                            <div
                              id={`collapseOne${i}`}
                              className="accordion-collapse collapse"
                              aria-labelledby={`heading${i}`}
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body faqAccordionBody">
                                <p>{item.answer}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="tab-pane fade"
          id="nav-protection"
          role="tabpanel"
          aria-labelledby="nav-protection-tab"
        >
          <div className="faqTabText">
            <h4 className="text-decoration-underline text-center mt-4 mb-4">
              Crop Protection
            </h4>
            <div className="row">
              <div className="col-lg-6 col-m-6">
                <div className="faqItem">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button faqAccordionBtn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Why should I sell on Luxliving Interior ?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            Luxliving Interior is one of the most trusted and
                            best E-commerce platforms. With over 50 crore
                            customers across 27000+ pin codes, Mamas
                            Tycoons is trusted by more than 500000 sellers
                            online throughout India. By selling online on
                            Flipkart, the sellers also get access to some
                            of India’s largest shopping festivals, such as
                            The Big Billion Days, The Big Diwali Sale, and
                            whatnot! Luxliving Interior .com provides a very
                            low cost of doing business.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button faqAccordionBtn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How does selling on Luxliving Interior .com work?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            Selling on Luxliving Interior is a very easy
                            process. All you have to do is list the
                            products that you wish to sell on Mamas
                            Tycoons .com. Once the order is placed, all
                            that is required from your end is that you
                            pack your product and deliver the product or
                            use Fulfillment by Flipkart (FBF) and let
                            Luxliving Interior deliver the product for you.
                            Once the product is dispatched, you will
                            receive your payment within 7-10 days* from
                            the date of dispatch.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button faqAccordionBtn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What is the minimum listing quantity to sell on
                          Luxliving Interior .com?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            To sell online on Luxliving Interior .com, all you
                            require is at least 1 product to be listed.
                            However, we recommend you to go for more than
                            1 product as Flipkart encompasses over 50
                            crore customers across 27000+ pin codes and
                            the trust of 500000 sellers.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button faqAccordionBtn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          What products can I sell on Luxliving Interior .com?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            Luxliving Interior .com has more than 3000+
                            categories that you can opt from and sell
                            online across 27000+ pin codes of India. Some
                            of the popular categories are Clothes, Sarees,
                            Electronics, Shoes, Jewelry, T-Shirts,
                            Furniture, Art, Books, Watch, Mobiles, Camera,
                            Bed, Groceries, Beauty Products, Plants,
                            Kitchenware, and many more. However, certain
                            categories require prior quality approval from
                            the internal committee before going live.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button faqAccordionBtn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          What do I need to register to sell on Mamas
                          Tycoons .com?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            For selling product online on Luxliving Interior
                            .com, you require the following details-
                          </p>
                          <ol>
                            <li>Details about your business</li>
                            <li>
                              Contact details (email id and phone number)
                            </li>
                            <li>
                              Details about your tax registration- GSTIN
                              (compulsory if your listed products are
                              taxable) and PAN (Mandatory for Book
                              Sellers)
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-m-6">
                <div className="accordion" id="accordionExample1">
                  <div className="accordion-item faqAccordionInfo">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button faqAccordionBtn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        I don’t have a website; can I still sell on Mamas
                        Tycoons .com?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample1"
                    >
                      <div className="accordion-body faqAccordionBody">
                        <p>
                          A big yes. For selling your products on Mamas
                          Tycoons .com, you don't require a website. You
                          will have access to Luxliving Interior Seller Hub
                          after completing your registration where you can
                          list your products and start selling. However,
                          Luxliving Interior .com charges a small amount of
                          marketplace fee once your product is sold out.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item faqAccordionInfo">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button faqAccordionBtn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="true"
                        aria-controls="collapseSeven"
                      >
                        What is FAssured?
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqAccordionBody">
                        <p>
                          FAssured by Luxliving Interior is a special
                          reliability program where Luxliving Interior will
                          provide additional visibility to your products.
                          It will also ensure that the product goes under
                          additional quality checks and gets delivered
                          within 2-4 days of ordering. A FAssured tag
                          guarantees more orders, faster delivery,
                          increased visibility, and higher quality. In
                          short, it is Flipkart’s seal of trust, quality,
                          and speed. By getting a FAssured badge, you can
                          get better revenue and earn trust from
                          customers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item faqAccordionInfo">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button faqAccordionBtn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        Can I offer both products and services on Mamas
                        Tycoons .com?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample1"
                    >
                      <div className="accordion-body faqAccordionBody">
                        <p>
                          Currently, Luxliving Interior only allows sellers to
                          sell their products online. However, as a
                          third-party service provider, you can offer
                          specific services to the Luxliving Interior sellers
                          to help grow their business, to know more,{" "}
                          <Link to="/faq">click here.</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="tab-pane fade"
          id="nav-nutrition"
          role="tabpanel"
          aria-labelledby="nav-nutrition-tab"
        >
          <div className="faqTabText">
            <h4 className="text-decoration-underline text-center mt-4 mb-4">
              Crop Nutrition
            </h4>
            <div className="row">
              <div className="col-lg-6 col-m-6">
                <div className="faqItem">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button faqAccordionBtn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Why should I sell on Luxliving Interior ?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            Luxliving Interior is one of the most trusted and
                            best E-commerce platforms. With over 50 crore
                            customers across 27000+ pin codes, Mamas
                            Tycoons is trusted by more than 500000 sellers
                            online throughout India. By selling online on
                            Flipkart, the sellers also get access to some
                            of India’s largest shopping festivals, such as
                            The Big Billion Days, The Big Diwali Sale, and
                            whatnot! Luxliving Interior .com provides a very
                            low cost of doing business.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button faqAccordionBtn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How does selling on Luxliving Interior .com work?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            Selling on Luxliving Interior is a very easy
                            process. All you have to do is list the
                            products that you wish to sell on Mamas
                            Tycoons .com. Once the order is placed, all
                            that is required from your end is that you
                            pack your product and deliver the product or
                            use Fulfillment by Flipkart (FBF) and let
                            Luxliving Interior deliver the product for you.
                            Once the product is dispatched, you will
                            receive your payment within 7-10 days* from
                            the date of dispatch.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button faqAccordionBtn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What is the minimum listing quantity to sell on
                          Luxliving Interior .com?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            To sell online on Luxliving Interior .com, all you
                            require is at least 1 product to be listed.
                            However, we recommend you to go for more than
                            1 product as Flipkart encompasses over 50
                            crore customers across 27000+ pin codes and
                            the trust of 500000 sellers.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button faqAccordionBtn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          What products can I sell on Luxliving Interior .com?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            Luxliving Interior .com has more than 3000+
                            categories that you can opt from and sell
                            online across 27000+ pin codes of India. Some
                            of the popular categories are Clothes, Sarees,
                            Electronics, Shoes, Jewelry, T-Shirts,
                            Furniture, Art, Books, Watch, Mobiles, Camera,
                            Bed, Groceries, Beauty Products, Plants,
                            Kitchenware, and many more. However, certain
                            categories require prior quality approval from
                            the internal committee before going live.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button faqAccordionBtn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          What do I need to register to sell on Mamas
                          Tycoons .com?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            For selling product online on Luxliving Interior
                            .com, you require the following details-
                          </p>
                          <ol>
                            <li>Details about your business</li>
                            <li>
                              Contact details (email id and phone number)
                            </li>
                            <li>
                              Details about your tax registration- GSTIN
                              (compulsory if your listed products are
                              taxable) and PAN (Mandatory for Book
                              Sellers)
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-m-6">
                <div className="accordion" id="accordionExample1">
                  <div className="accordion-item faqAccordionInfo">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button faqAccordionBtn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        I don’t have a website; can I still sell on Mamas
                        Tycoons .com?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample1"
                    >
                      <div className="accordion-body faqAccordionBody">
                        <p>
                          A big yes. For selling your products on Mamas
                          Tycoons .com, you don't require a website. You
                          will have access to Luxliving Interior Seller Hub
                          after completing your registration where you can
                          list your products and start selling. However,
                          Luxliving Interior .com charges a small amount of
                          marketplace fee once your product is sold out.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item faqAccordionInfo">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button faqAccordionBtn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="true"
                        aria-controls="collapseSeven"
                      >
                        What is FAssured?
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqAccordionBody">
                        <p>
                          FAssured by Luxliving Interior is a special
                          reliability program where Luxliving Interior will
                          provide additional visibility to your products.
                          It will also ensure that the product goes under
                          additional quality checks and gets delivered
                          within 2-4 days of ordering. A FAssured tag
                          guarantees more orders, faster delivery,
                          increased visibility, and higher quality. In
                          short, it is Flipkart’s seal of trust, quality,
                          and speed. By getting a FAssured badge, you can
                          get better revenue and earn trust from
                          customers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item faqAccordionInfo">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button faqAccordionBtn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        Can I offer both products and services on Mamas
                        Tycoons .com?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample1"
                    >
                      <div className="accordion-body faqAccordionBody">
                        <p>
                          Currently, Luxliving Interior only allows sellers to
                          sell their products online. However, as a
                          third-party service provider, you can offer
                          specific services to the Luxliving Interior sellers
                          to help grow their business, to know more,{" "}
                          <Link to="/faq">click here.</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="tab-pane fade"
          id="nav-chemicals"
          role="tabpanel"
          aria-labelledby="nav-chemicals-tab"
        >
          <div className="faqTabText">
            <h4 className="text-decoration-underline text-center mt-4 mb-4">
              Chemicals
            </h4>
            <div className="row">
              <div className="col-lg-6 col-m-6">
                <div className="faqItem">
                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button faqAccordionBtn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Why should I sell on Luxliving Interior ?
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            Luxliving Interior is one of the most trusted and
                            best E-commerce platforms. With over 50 crore
                            customers across 27000+ pin codes, Mamas
                            Tycoons is trusted by more than 500000 sellers
                            online throughout India. By selling online on
                            Flipkart, the sellers also get access to some
                            of India’s largest shopping festivals, such as
                            The Big Billion Days, The Big Diwali Sale, and
                            whatnot! Luxliving Interior .com provides a very
                            low cost of doing business.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button faqAccordionBtn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          How does selling on Luxliving Interior .com work?
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            Selling on Luxliving Interior is a very easy
                            process. All you have to do is list the
                            products that you wish to sell on Mamas
                            Tycoons .com. Once the order is placed, all
                            that is required from your end is that you
                            pack your product and deliver the product or
                            use Fulfillment by Flipkart (FBF) and let
                            Luxliving Interior deliver the product for you.
                            Once the product is dispatched, you will
                            receive your payment within 7-10 days* from
                            the date of dispatch.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button faqAccordionBtn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          What is the minimum listing quantity to sell on
                          Luxliving Interior .com?
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            To sell online on Luxliving Interior .com, all you
                            require is at least 1 product to be listed.
                            However, we recommend you to go for more than
                            1 product as Flipkart encompasses over 50
                            crore customers across 27000+ pin codes and
                            the trust of 500000 sellers.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingFour">
                        <button
                          className="accordion-button faqAccordionBtn"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="true"
                          aria-controls="collapseFour"
                        >
                          What products can I sell on Luxliving Interior .com?
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            Luxliving Interior .com has more than 3000+
                            categories that you can opt from and sell
                            online across 27000+ pin codes of India. Some
                            of the popular categories are Clothes, Sarees,
                            Electronics, Shoes, Jewelry, T-Shirts,
                            Furniture, Art, Books, Watch, Mobiles, Camera,
                            Bed, Groceries, Beauty Products, Plants,
                            Kitchenware, and many more. However, certain
                            categories require prior quality approval from
                            the internal committee before going live.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item faqAccordionInfo">
                      <h2 className="accordion-header" id="headingFive">
                        <button
                          className="accordion-button faqAccordionBtn collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                          What do I need to register to sell on Mamas
                          Tycoons .com?
                        </button>
                      </h2>
                      <div
                        id="collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingFive"
                        data-bs-parent="#accordionExample1"
                      >
                        <div className="accordion-body faqAccordionBody">
                          <p>
                            For selling product online on Luxliving Interior
                            .com, you require the following details-
                          </p>
                          <ol>
                            <li>Details about your business</li>
                            <li>
                              Contact details (email id and phone number)
                            </li>
                            <li>
                              Details about your tax registration- GSTIN
                              (compulsory if your listed products are
                              taxable) and PAN (Mandatory for Book
                              Sellers)
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-m-6">
                <div className="accordion" id="accordionExample1">
                  <div className="accordion-item faqAccordionInfo">
                    <h2 className="accordion-header" id="headingSix">
                      <button
                        className="accordion-button faqAccordionBtn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        I don’t have a website; can I still sell on Mamas
                        Tycoons .com?
                      </button>
                    </h2>
                    <div
                      id="collapseSix"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSix"
                      data-bs-parent="#accordionExample1"
                    >
                      <div className="accordion-body faqAccordionBody">
                        <p>
                          A big yes. For selling your products on Mamas
                          Tycoons .com, you don't require a website. You
                          will have access to Luxliving Interior Seller Hub
                          after completing your registration where you can
                          list your products and start selling. However,
                          Luxliving Interior .com charges a small amount of
                          marketplace fee once your product is sold out.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item faqAccordionInfo">
                    <h2 className="accordion-header" id="headingSeven">
                      <button
                        className="accordion-button faqAccordionBtn"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSeven"
                        aria-expanded="true"
                        aria-controls="collapseSeven"
                      >
                        What is FAssured?
                      </button>
                    </h2>
                    <div
                      id="collapseSeven"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingSeven"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body faqAccordionBody">
                        <p>
                          FAssured by Luxliving Interior is a special
                          reliability program where Luxliving Interior will
                          provide additional visibility to your products.
                          It will also ensure that the product goes under
                          additional quality checks and gets delivered
                          within 2-4 days of ordering. A FAssured tag
                          guarantees more orders, faster delivery,
                          increased visibility, and higher quality. In
                          short, it is Flipkart’s seal of trust, quality,
                          and speed. By getting a FAssured badge, you can
                          get better revenue and earn trust from
                          customers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item faqAccordionInfo">
                    <h2 className="accordion-header" id="headingEight">
                      <button
                        className="accordion-button faqAccordionBtn collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseEight"
                        aria-expanded="false"
                        aria-controls="collapseEight"
                      >
                        Can I offer both products and services on Mamas
                        Tycoons .com?
                      </button>
                    </h2>
                    <div
                      id="collapseEight"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingEight"
                      data-bs-parent="#accordionExample1"
                    >
                      <div className="accordion-body faqAccordionBody">
                        <p>
                          Currently, Luxliving Interior only allows sellers to
                          sell their products online. However, as a
                          third-party service provider, you can offer
                          specific services to the Luxliving Interior sellers
                          to help grow their business, to know more,{" "}
                          <Link to="/faq">click here.</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default FaqsComponents
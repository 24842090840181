import React from "react";


const BrandingPlace = ({title,description ,image ,alt ,className}) => {
  return (
    <div className="avtar-container">
      <div className="avtar-image">
        <img
          src={image}
          alt="branding-place"
          className="branding-place-img w-100 h-100 "
        />
      </div>
      <h4 className="avtar-heading">
        THE SMALL PART OF<span> INDIA</span>
      </h4>
      <p className="avtar-desc">
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde quibusdam
        quia at amet placeat odit numquam, aperiam sapiente eaque fugit.
        Similique alias distinctio magni nobis facilis dolore recusandae facere
        quae?
      </p>
    </div>
  );
};

export default BrandingPlace;

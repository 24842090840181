import React from 'react'; // Make sure to create and import the CSS file
import aboutusImage7 from "../../../src/assets/luxliving-image/about--7.webp";
import aboutusImage6 from "../../../src/assets/luxliving-image/about--6.webp";
import aboutusImage5 from "../../../src/assets/luxliving-image/about--5.webp";
import aboutusImage4 from "../../../src/assets/luxliving-image/about--4.webp";
import aboutusImage3 from "../../../src/assets/luxliving-image/about--3.webp";
import aboutusImage2 from "../../../src/assets/luxliving-image/about--2.webp";
import aboutusImage1 from "../../../src/assets/luxliving-image/about--1.webp";

const Gallery = () => {
  return (
    <section className="gallery-section">
      <div className="gallery-container ">
        <div className="gallery-left ">
          <div className="gallery-item">
            <img src={ aboutusImage7} alt="This is an image" className="gallery-image" />
            <div className="overlay">
              <p className="overlay-text">Solvay Table</p>
            </div>
          </div>
        </div>
        <div className="gallery-right">
          <div className="gallery-item">
            <img src={ aboutusImage2} alt="This is an image" className="gallery-image" />
            <div className="overlay">
              <p className="overlay-text">Kurlon Crescent Sofa</p>
            </div>
          </div>
          <div className="gallery-item">
            <img src={ aboutusImage1} alt="This is an image" className="gallery-image" />
            <div className="overlay">
              <p className="overlay-text">Duplo Sofa</p>
            </div>
          </div>
          <div className="gallery-item">
            <img src={ aboutusImage3} alt="This is an image" className="gallery-image" />
            <div className="overlay">
              <p className="overlay-text">Accent Chair</p>
            </div>
          </div>
          <div className="gallery-item">
            <img src={ aboutusImage4} alt="This is an image" className="gallery-image" />
            <div className="overlay">
              <p className="overlay-text">Galaxy Dining Chair</p>
            </div>
          </div>
          <div className="gallery-item">
            <img src={ aboutusImage5} alt="This is an image" className="gallery-image" />
            <div className="overlay">
              <p className="overlay-text">Clarendor Sofaset</p>
            </div>
          </div>
          <div className="gallery-item">
            <img src={ aboutusImage6} alt="This is an image" className="gallery-image" />
            <div className="overlay">
              <p className="overlay-text">Mayfield Sofaset</p>
            </div>
          </div>
          
        </div>
      </div>



        

    </section>
  );
};

export default Gallery;

import React from "react";
import { FaAddressBook } from "react-icons/fa";
import { BsFillPhoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { Ri24HoursFill } from "react-icons/ri";

function ContactAddress({ t }) {
  return (
    <>
      <div className="connectSec">
        <div className="row">
          <div className="col-sm-6 col-lg-6">
            <div className="feature-box text-center">
              <div className="feature-box-content">
                <BsFillPhoneFill />
                <h4>{t("Phone Number")}</h4>
                <p>
                  <a href="tel:+91-9808133166">+91-9808133166</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-6">
            <div className="feature-box text-center">
              <div className="feature-box-content">
                <MdEmail />
                <h4>{t("E-mail Address")}</h4>

                <p>
                  <a href="mailto:daud@gmail.com">
                 daud@gmail.com
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-12">
            <div className="feature-box text-center">
              <div className="feature-box-content">
                <FaAddressBook />
                <h4>{t("Address")}</h4>
                <p>
                98b Nambardar estate, NFC road, New Delhi, India 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactAddress;

import axios from "axios";
import React, { useEffect, useState } from "react";
// import login from "../../assets/img/rki/login/login-img.png";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineGoogle } from "react-icons/ai";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSetLoginMutation } from "../products/productSlice";
import { Spinner } from "react-bootstrap";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
// import GoogleLogin from "react-google-login";
// import { LoginSocialFacebook } from "reactjs-social-login";
import { FcGoogle } from "react-icons/fc";
import { useTranslation } from "react-i18next";
import { base_url } from "../../server";

function Login() {
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const handleChangeLogin = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const cloneData = { ...loginForm };
    cloneData[name] = value;
    setLoginForm(cloneData);
  };

  const [loginFormStatus, { data, isError, isSuccess, isLoading }] =
    useSetLoginMutation();
  const params = useParams();
  useEffect(() => {
    if (data?.firstname) {
      if (params?.login) {
        window.localStorage.setItem("token", data.token);
        window.localStorage.setItem("user_id", data._id);
        window.localStorage.setItem("isLogin", true);
        window.localStorage.setItem("email", data.email);
        window.localStorage.setItem("profilePic", data.image.url);
        window.localStorage.setItem(
          "userName",
          `${data.firstname} ${data.lastname}`
        );
        navigate("/cart");
        window.location.reload();
        return;
      }
      setTimeout(() => {
        window.localStorage.setItem("token", data.token);
        window.localStorage.setItem("user_id", data._id);
        window.localStorage.setItem("isLogin", true);
        window.localStorage.setItem("email", data.email);
        window.localStorage.setItem("profilePic", data.image.url);
        window.localStorage.setItem(
          "userName",
          `${data.firstname} ${data.lastname}`
        );
        navigate("/");
        window.location.reload();
      }, 1000);
    }
  }, [isLoading, isSuccess]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    loginFormStatus(loginForm);
  };

  const [gmailData, setGailData] = useState();

  const [isGoogleLogin, seIGoogle] = useState(false);
  const baseUrl = base_url();
  const sendValueGoogle = async (val) => {
    seIGoogle(true);
    try {
      const res = await axios.post(`${baseUrl}socialMedia/google`, {
        access_token: val?.data?.access_token,
      });

      window.localStorage.setItem("token", res.data.token);
      window.localStorage.setItem("user_id", res.data._id);
      window.localStorage.setItem("isLogin", true);
      window.localStorage.setItem("email", res.data.email);
      // window.localStorage.setItem("profilePic", data?.image?.url);
      window.localStorage.setItem(
        "userName",
        `${res?.data.firstname} ${res?.data.lastname}`
      );
      navigate("/");
    } catch (error) {}
    seIGoogle(false);
  };

  useEffect(() => {
    if (gmailData) {
      sendValueGoogle(gmailData);
    }
  }, [gmailData]);

  // console.log(gmailData);

  const responseFacebook = (response) => {
    console.log(response);
  };

  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Login | mamastycoon</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      {/* <CustomToaster color={showTaoster.color} show={showTaoster.show} setShow={handleToaster} message={showTaoster.message} position="bottom-end" delay={10000} /> */}
      <div className="registrationDetail">
        <div className="container">
          <div className="registrationInfo">
            <div className="registerContent">
              {isGoogleLogin && (
                <div className="preloaderCount">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
              <div className="contentHeader">
                <h3>{t("Stay Updated on your professional world")}</h3>
                <p>{t("Sign in with your mobile number to get started")}</p>
              </div>
              <div className="contentFooter">
                {/* <img src={login} alt="Login" className="img-fluid" /> */}
              </div>
            </div>
            <div className="registerForm">
              <h4 className="mb-4">{t("Login to your account")}.</h4>
              <form className="registerFormField" onSubmit={handleLoginSubmit}>
                <div className="mb-3">
                  <input
                    type="email"
                    placeholder={t("email")}
                    className="form-control"
                    autoComplete="off"
                    name="email"
                    onChange={handleChangeLogin}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    placeholder={t("Password")}
                    className="form-control"
                    autoComplete="off"
                    name="password"
                    onChange={handleChangeLogin}
                  />
                </div>
                <div className="form-check mb-3 forgotInfo">
                  <div className="rememberText">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultValue
                      id="flexCheckDefault"
                    />
                    <label
                      className="form-check-label agreeCheck"
                      htmlFor="flexCheckDefault"
                    >
                      {t("Remember Me")}
                    </label>
                  </div>
                  <div className="forgotText">
                    <Link to="/reset">{t("Forgot password")}?</Link>
                  </div>
                </div>
                {isError && <h4 style={{ color: "red" }}>login Fail ! </h4>}
                {isSuccess && <h4>login Successfully !</h4>}
                <button
                  className="btn btn-primary createAccount"
                  type="submit"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {t("welcome_to_reactt")}
                  {isLoading && (
                    <Spinner style={{ marginLeft: "7px" }} animation="border" />
                  )}
                </button>
              </form>
              <div className="joinWith">
                <span>{t("or login with")}</span>
              </div>
              <div className="connectWith">
                <ul>
                  <li>
                    {/* <a href="#"
                      appId="1007501343742455"
                      autoLoad={false}
                      // fields="name,email,picture"
                      callback={responseFacebook}
                      className="facebook">
                      <FaFacebookF />
                    </a> */}

                    <LoginSocialFacebook
                      client_id="1007501343742455"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      onResolve={(provider, data) => {
                        // setGailData(provider)
                        console.log(provider);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <a href="#" className="facebook">
                        <FaFacebookF />
                      </a>
                    </LoginSocialFacebook>

                    {/* <FacebookLogin
                      appId="1007501343742455"
                      autoLoad={false}
                      // fields="name,email,picture"
                      callback={responseFacebook}
                      className="facebook"
                    /> */}

                    {/* <LoginSocialFacebook appId="1007501343742455"
                      onResolve={(res) => {
                        console.log(res);
                      }}
                      onReject={(rej) => {
                        console.log(rej);
                      }}
                    >
                    </LoginSocialFacebook> */}
                  </li>

                  <li>
                    <a href="#" className="twitter">
                      <BsTwitter />
                    </a>
                  </li>

                  <li>
                    <LoginSocialGoogle
                      client_id="459720570554-fa48vkc62ju5obdmj986bfmoh0g5gshs.apps.googleusercontent.com"
                      scope="email"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      onResolve={(provider, data) => {
                        setGailData(provider);
                      }}
                      onReject={(err) => {
                        console.log(err);
                      }}
                    >
                      <a href="#" className="google">
                        <FcGoogle />
                      </a>
                    </LoginSocialGoogle>
                  </li>
                </ul>
              </div>
              <div className="alreadyAccount">
                <p>{t("Dont have an account")}?</p>
                <Link to="/registration">{t("Register Now")}</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

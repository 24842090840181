import React from "react";
import { Link, useNavigate } from "react-router-dom";
import banch from "../../../assets/luxliving-image/p.webp";
import banch1 from "../../../assets/luxliving-image/Nestroots-metallic-napkin-holder-tissue-paper-holder-for-kitchen-and-dining-table-tissue-box-2.webp";
import banch2 from "../../../assets/luxliving-image/Nestroots-metallic-napkin-holder-tissue-paper-holder-for-kitchen-and-dining-table-tissue-box-2_45914c26-992d-448e-ad50-fcaa97e0a699.webp";
import banch3 from "../../../assets/luxliving-image/antique-brass-masala-box-india-gift-box-kitchen-masala-box-stand-metal-spice-jar-lids-gift-set-spice-jar-with-stand-mouth-freshner-box-spice-jar-with-wooden-lid-dry-fruits-storag.webp";

import { useTranslation } from "react-i18next";

function BestSeller() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const changeRoute = (route) => {
    if (route == "1") {
      navigate("/product/category/31/Baby-bottles");
    }
    if (route == "2") {
      navigate("/product/category/39/Dishes-and-Foods");
    }
    if (route == "3") {
      navigate("/product/category/32/Bibs-and-Pacifiers");
    }
    if (route == "4") {
      navigate(
        "/product/50/1pc%20Baby%20Bottle%20Drying%20Rack%20With%20Tray,%20High%20Capacity%20Bottle%20Dryer%20Holder%20For%20Baby%20Bottle"
      );
    }
  };
  return (
    <>
      <section className="bestSeller sectionPD">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="fisherman-content">
                <h3>{t("OUR TOP BEST SELLER")}</h3>
                <p></p>
              </div>
            </div>
            <div className="row h-100">
              <div className="col-lg-6 h-100 " onClick={() => changeRoute("4")}>
                <div
                  className=" border sellerProductCard"
                  style={{ height: "500px", width: "100%" }}
                >
                  <div className="sellerProductCard h-100">
                    <img
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={banch}
                      alt="Product"
                      className="mainimgbuy img-fluid"
                    />
                    <div className="sellerProductText ">
                      <h2>Our Top Best Seller</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 h-100" onClick={() => changeRoute("1")}>
                <div className="asideSeller row h-100">
                  <div
                    className="col-lg-6 h-50 "
                    onClick={() => changeRoute("1")}
                  >
                    <div
                      className=" border sellerProductCard overflow-hidden"
                      style={{ height: "250px", width: "100%" }}
                    >
                      <img
                        src={banch1}
                        alt="Product"
                        className="img-fluid w-100 h-100 "
                      />
                      <div className="sellerProductContent">
                        <h2 className="">Inseparable Teak Wood Napkin Holder</h2>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-6 h-50 "
                    onClick={() => changeRoute("2")}
                  >
                    <div
                      className=" border asideSellerCard"
                      style={{ height: "250px", width: "100%" }}
                    >
                      <div className="sellerProductCard h-100">
                        <img src={banch2} alt="Product" className="img-fluid" />
                        <div className="sellerProductContent">
                          <h2 className="">Fun Time</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-12 h-50 "
                    onClick={() => changeRoute("3")}
                  >
                    <div
                      className=" border asideSellerCard"
                      style={{
                        height: "250px",
                        width: "100%",
                        overflow: "hidden",
                      }}
                    >
                      <div className="sellerProductCard  overflow-hidden d-flex justify-content-center align-items-center h-100 p-2">
                        <img
                          src={banch3}
                          alt="Product"
                          className=" object-fit-cover"
                        />
                        <div className="sellerProductContent full">
                          <h2 className="">Classic Acacia Wood Masala Box</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BestSeller;

import React from "react";
import { Helmet } from "react-helmet";
import "./aboutUs.css";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
import { useTranslation } from "react-i18next";
import aboutusImage from "../../../src/assets/luxliving-image/new-about-img.webp";
import { FaPlay } from "react-icons/fa";
import { GoArrowRight } from "react-icons/go";
import Gallery from "./GallerySection";
import Info from "./Info";
import Testimonial from "./Testimonial";
import AboutDescription from "./AboutDescription";
import BrandingPlace from "./BrandingPlace";
import indiagate from "../../assets/luxliving-image/india-gate.webp";
import fest from "../../assets/luxliving-image/fest.jpg";
import indianCulture from "../../assets/luxliving-image/india-map-culture.webp";

const AboutUs = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>About us | Luxliving Interior</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at Luxliving Interior. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      <Breadcrumb title="About Us" t={t} />
      <article>
        <section className="about-us-container">
          <div className="  d-flex flex-column flex-md-row justify-content-between gap-5">
            <div className="border p-2 col-md-6 overflow-hidden">
              <picture>
                <img
                  src={aboutusImage}
                  alt="This is an image"
                  className=" scale-110"
                />
              </picture>
            </div>
            <div className="col-md-6 ">
              <h2 className=" heading-h2  ">Calio Bird Chair</h2>
              <p>
                From the calypso music of the Caribbean, the styles or genres of
                music that exist across the world are mind-boggling to say the
                least. Starting from the Calypso styles of the Afro-Caribbean to
                the Brazilian Zumba styles of dance music, the Western classical
                styles, the Indian genres, and the Sufi styles besides the
                Arabic offer amazing variety.
              </p>
              <ul className="row   p-0   ">
                <li className="col-6 mt-3 fs-5">
                  <FaPlay size={10} style={{ marginRight: "8px" }} />
                  Cello Plastic Chair
                </li>
                <li className="col-6  mt-3  fs-5 ">
                  <FaPlay size={10} style={{ marginRight: "8px" }} />
                  Wood Chair
                </li>
                <li className="col-6  mt-3 fs-5">
                  <FaPlay size={10} style={{ marginRight: "8px" }} />
                  TimberTaste Chair
                </li>
                <li className="col-6  mt-3 fs-5 fw-normal">
                  <FaPlay size={10} style={{ marginRight: "8px" }} />
                  Teak Chair
                </li>
              </ul>
              <button className="btn btn-primary mt-3">
                {" "}
                View more{" "}
                <span>
                  {" "}
                  <GoArrowRight size={20} />{" "}
                </span>
              </button>
            </div>
          </div>
          <div className=" mt-5">
            <AboutDescription />
          </div>
          <div className="branding-section   container-fluid ">
            <div className=" row grid-container">
            <div className=" col-md-6  col-lg-4 col-12  "><BrandingPlace title={''} image={indiagate} /></div>
            <div className=" col-md-6  col-lg-4 col-12"><BrandingPlace image={fest} /></div>
            <div className=" col-md-6 col-lg-4 col-12"><BrandingPlace  image={indianCulture}/></div>
            </div>
          </div>
        </section>

        <Gallery />
        <section className="about-us-container info-container">
          <Info />
        </section>
        <Testimonial />
      </article>
    </>
  );
};

export default AboutUs;

import React from "react";
import cutting from "../../assets/luxliving-image/woodcutting.jpg";
import crafting from "../../assets/luxliving-image/carft.jpg";

const AboutDescription = () => {
  return (
    <div>
      <div className=" about-us-container-heading">
        <h2 className="heading-aboutus">
          {" "}
          ABOUT <span> US</span>
        </h2>
        <p>
          {" "}
          We are brand that celebrates sustainability Lorem, ipsum dolor sit
          amet consectetur adipisicing elit. Quidem illo hic iure porro totam
          dolorum quod? Quaerat reiciendis illo culpa nulla quisquam suscipit,
          officia asperiores ipsum quis voluptate eligendi architecto quia
          perspiciatis cumque ea ullam ipsa reprehenderit sapiente beatae veniam
          mollitia.
        </p>
      </div>{" "}
      <picture>
        <img
          src={cutting}
          alt=" this aabout cutting edge"
          width=""
          height=""
          className=" "
        />
      </picture>
      <div className="container mt-5">
        <div className="row">
          <div className="col-md-6 p-0  d-flex flex-column justify-content-center align-items-center">
            <h3 className=" d-flex  flex-column crafti-heading ">
              " WE ARE
              <span className="span-1">MODERN</span>
              <span className=" span-2">MINDFULL</span>&
              <span className=" span-3"> UNIQUELY INDIAN ! "</span>
            </h3>
          </div>
          <div className="col-md-6 p-0">
            <img
              src={crafting}
              width=""
              height=""
              alt="this crufting edge style "
              className=" crafting-img"
            />
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row  ">
          <div className="col-md-6 p-0">
            <img
              src={crafting}
              width=""
              height=""
              alt="this crufting edge style "
              className=" crafting-img"
            />
          </div>
          <div className="col-md-6 p-0  d-flex flex-column justify-content-end align-items-end ">
            <h3 className="  crafti-heading ">
              " WE ARE ARE <span className=" span-2">BORN </span> "
            </h3>
            <p className="born-para" >
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Earum
              laboriosam necessitatibus ipsa mollitia, nobis veritatis!
              Obcaecati exercitationem aliquam animi! Sequi quos excepturi ipsum
              assumenda nesciunt amet, eligendi veritatis aut ab repudiandae
              adipisci dignissimos voluptatibus quidem. Sed, error assumenda.
              Architecto culpa velit harum nesciunt autem debitis ab explicabo
              ducimus, ipsum magni blanditiis perspiciatis aperiam amet saepe
              reprehenderit deleniti libero vitae? Dignissimos velit sequi
              laudantium quidem nemo perferendis id distinctio eum repellat quas
              rerum minima molestiae facilis nesciunt maxime veniam quos vero,
              sint maiores atque, optio iste voluptate. Ea quaerat maxime sunt,
              fugit, veritatis iste, unde placeat voluptatem ut perferendis
              error quibusdam!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutDescription;
